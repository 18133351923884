<template>
  <v-container class="login">
    <div class="d-flex flex-col justify-center align-center">
      <v-img  
        class="ml-4"
        :aspect-ratio="16 / 9"
        :src="logo"
        contain
        width="60"
        height="80"
        max-width="250"
      />
    </div>

    <v-col class="pr-2 pt-12" cols="12">
      <form  @submit.prevent="submitLogin">
        <v-row>
          <v-col cols="12" md="2" xs2 class="col-label-class" style="pading-bottom: 0px;">
            <span style="color: black">{{$t("message.label-email")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs2 class="input-class">
            <v-text-field 
              outlined
              background-color="white"
              v-model="userLogin.email"
              required
            />
          </v-col>
          <v-col cols="12" md="2" xs2 class="col-label-class" >
            <span style="color: black">{{$t("message.label-password")}}</span>
          </v-col>
          <v-col cols="12" md="10" xs2 class="input-class"> 
            <v-text-field 
              v-model="userLogin.password"
              :type="showPassword ? 'text' : 'password'"
              name="password" 
              autocomplete="on"
              required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              outlined
              background-color="white"
            />
          </v-col>
        </v-row>
          <router-link to="/forgotPassword" style="color: black;">
            <span class="d-flex flex-row align-start justify-start" style="margin-top: 10px">{{$t("message.label-forget-pass")}}</span>
          </router-link>
          <v-col class="" cols="12">
            <v-row justify="center">
              <v-col cols="12" md="2" xs2>
                <v-btn color="#E93B81" type="submit" @click.prevent="submitLogin()" :disabled="!isValid" height="45px" width="100%" style="border: 1px solid black">
                  <span style="color: white">{{$t("message.label-login")}}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" md="2" xs2>
                <v-btn color="#D5D5D5" type="button" @click.prevent="register()" height="45px" width="100%" style="border: 1px solid black">
                  <span style="color: #E93B81">{{$t("message.label-register")}}</span>
                </v-btn>
              </v-col>
              <!-- <v-col cols="12" md="2" xs2>
                <v-btn @click.prevent="lineLogin()" class="line-button" style="width: 100%; padding-left: 0px;padding-right:5px; background-color: #06C755; color: white; height: 45px; weight: 500"> 
                  <img src="../../public/LINE_Brand_icon.png" alt="line" class="width: 300px" height="45px">
                  {{ $t("message.btn-line-login") }}
                </v-btn>
              </v-col> -->
            </v-row>
          </v-col>
      </form>
    </v-col>
  </v-container>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';
import { validationMixin } from "vuelidate";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  validations: {
    userLogin :{
      email: { required,  email},
      password: { required }
    }
  },
  data() {
    return {
      logo: require("../assets/logo_wbase.png"),
      isValid: true,
      errMsg: "",
      dataErr: "",
      showPassword: false,
      closePage: mdiCloseThick,
      userLogin: {
        email: "",
        password: "",
        message: {}
      },
    }
  },
  methods: {
    backPage() {
      this.$router.push('/')
    },
    register() {
      this.$router.push('/tempRegister')
    },
    submitLogin() {
      this.userLogin.message.title = this.$t('message.title-login-success-message')
      this.$store.dispatch('auth_module/login', this.userLogin)
    },
    // lineLogin() {
    //   this.$store.dispatch('auth_module/lineLogin')
    // }
    handleIsLogin() {
      if($cookies.get("isLogin") == 0 && localStorage.usr_tkn) {
        localStorage.removeItem("usr_tkn");
      }
      if($cookies.get("isLogin") == 0) {
        localStorage.removeItem("email")
        localStorage.removeItem("token")
        localStorage.removeItem("user_register")
      }
    }
  },
  
  computed: {
    reqErrorMessage() {
      return this.$store.state.auth_module.emailErr
    },

    emailErrors() {
      let errors = [];
      if (!this.$v.userLogin.email.$dirty) return errors;
      // !this.$v.userLogin.email.required && errors.push(("email is required"));
      !this.$v.userLogin.email.required && errors.push(this.$t("message.err-msg-email-required"));
      // !this.$v.userLogin.email.email && errors.push(("must be valid email"));
      !this.$v.userLogin.email.email && errors.push(this.$t("message.err-msg-email"));

      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.userLogin.password.$dirty) return errors;
      // !this.$v.userLogin.password.required && errors.push(("password is required"));
      !this.$v.userLogin.password.required && errors.push(this.$t("message.err-msg-password-required"));
      return errors;
    },
  },
  created() {
    this.handleIsLogin()
  },
}
</script>
<style>
.col-label-class {
  padding: 5px 0px 0px 12px !important;
  color: white !important;
}
.login {
  /* background-color: #3f51b5; */
  background-color: #fff;
  height: 100vh;
  max-width: 100vw !important;
}
.toolbar-title-class {
  /* font-weight: 800; */
}
.line-button {
  /* width: 150px;
  height: 44px;
  display: block;
  background-color: #06C755; */
}
</style>